<template>
  <div
    class="subheader py-2 py-lg-12 subheader-transparent"
    v-bind:class="subheaderClasses"
    id="kt_subheader"
  >
    <div
      class="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
      v-bind:class="{
        'container-fluid': widthFluid,
        'container-fluid': !widthFluid,
      }"
    >
      <div class="d-flex align-items-center flex-wrap mr-2">
        <!--begin::Heading-->
        <div class="d-flex flex-column">
          <!--begin::Title-->
          <!-- <h2 class="text-white font-weight-bold my-2 mr-5">
            {{ title }}
          </h2> -->
          <ul class="d-flex align-items-center font-weight-bold my-2">
            <router-link :to="'/'" class="opacity-75 hover-opacity-100">
              <i class="flaticon2-shelter text-white icon-2x"></i>
            </router-link>

            <template v-for="(breadcrumb, i) in breadcrumbs">
              <span
                class="label label-dot label-sm bg-white opacity-75 mx-3"
                :key="i"
              ></span>
              <router-link
                :key="`${i}-${breadcrumb.id}`"
                v-if="breadcrumb.route"
                :to="breadcrumb.route"
                class="text-white text-hover-white opacity-75 hover-opacity-100"
              >
                <!-- {{ breadcrumb.route }} -->
                <h4>{{ breadcrumb.title }}</h4>
              </router-link>
              <h4
                class="text-white text-hover-white opacity-75 hover-opacity-100"
                :key="`${i}-${breadcrumb.id}`"
                v-if="!breadcrumb.route"
              >
                {{ breadcrumb.title }}
              </h4>
            </template>
          </ul>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div
          :style="{ textAlign: 'right' }"
          v-if="
            breadcrumbs &&
            breadcrumbs[0] &&
            breadcrumbs[0].lineId &&
            breadcrumbs[0].conveyorId
          "
        >
          <b-row class="mt-8 pr-10">
            <!-- <b-col updated successfullycol cols="10"></b-col> -->
            <b-col cols="6">
              <a
                @click="goToHome('prev')"
                class="btn btn-light font-weight-bold btn-sm"
                v-if="prevConveyorId"
              >
                Prev
              </a>
              <a
                class="btn btn-light font-weight-bold btn-sm disabled"
                disabled
                v-else
              >
                Prev
                <!-- <b-button squared variant="primary">Prev</b-button> -->
              </a>
            </b-col>
            <b-col cols="6">
              <a
                @click="goToHome('next')"
                class="btn btn-light font-weight-bold btn-sm"
                v-if="nextConveyorId"
              >
                Next
              </a>
              <a
                class="btn btn-light font-weight-bold btn-sm disabled"
                disabled
                v-else
              >
                Next
                <!-- <b-button squared variant="primary">Next</b-button> -->
              </a>
            </b-col>
          </b-row>
        </div>

        <!--begin::Button-->
        <!-- <router-link :to="'/'">
          <a
            class="btn btn-transparent-white font-weight-bold py-3 px-6 mr-2"
          >
            Home
          </a>
        </router-link> -->
        <!--end::Button-->
        <!--begin::Dropdown-->
        <!-- <b-dropdown
          size="sm"
          variant="link"
          toggle-class="custom-v-dropdown btn btn-white font-weight-bold py-3 px-6"
          no-caret
          right
          no-flip
          text="Actions"
          v-b-tooltip.hover="'Quick actions'"
        > -->
        <!--begin::Navigation-->
        <!-- <div class="navi navi-hover min-w-md-250px">
            <b-dropdown-text tag="div" class="navi-item">
              <a href="#" class="navi-link">
                <span class="navi-icon">
                  <i class="flaticon2-drop"></i>
                </span>
                <span class="navi-text">New Group</span>
              </a>
            </b-dropdown-text>
            <b-dropdown-text tag="div" class="navi-item">
              <a href="#" class="navi-link">
                <span class="navi-icon">
                  <i class="flaticon2-list-3"></i>
                </span>
                <span class="navi-text">Contacts</span>
              </a>
            </b-dropdown-text>
            <b-dropdown-text tag="div" class="navi-item">
              <a href="#" class="navi-link">
                <span class="navi-icon">
                  <i class="flaticon2-rocket-1"></i>
                </span>
                <span class="navi-text">Groups</span>
                <span class="navi-link-badge">
                  <span
                    class="label label-light-primary label-inline font-weight-bold"
                    >new</span
                  >
                </span>
              </a>
            </b-dropdown-text>
            <b-dropdown-text tag="div" class="navi-item">
              <a href="#" class="navi-link">
                <span class="navi-icon">
                  <i class="flaticon2-bell-2"></i>
                </span>
                <span class="navi-text">Calls</span>
              </a>
            </b-dropdown-text>
            <b-dropdown-text tag="div" class="navi-item">
              <a href="#" class="navi-link">
                <span class="navi-icon">
                  <i class="flaticon2-gear"></i>
                </span>
                <span class="navi-text">Settings</span>
              </a>
            </b-dropdown-text>
            <b-dropdown-text
              tag="div"
              class="navi-separator my-3"
            ></b-dropdown-text>
            <b-dropdown-text tag="div" class="navi-item">
              <a href="#" class="navi-link">
                <span class="navi-icon">
                  <i class="flaticon2-magnifier-tool"></i>
                </span>
                <span class="navi-text">Help</span>
              </a>
            </b-dropdown-text>
            <b-dropdown-text tag="div" class="navi-item">
              <a href="#" class="navi-link">
                <span class="navi-icon">
                  <i class="flaticon2-bell-2"></i>
                </span>
                <span class="navi-text">Privacy</span>
                <span class="navi-link-badge">
                  <span
                    class="label label-light-danger label-rounded font-weight-bold"
                    >5</span
                  >
                </span>
              </a>
            </b-dropdown-text>
          </div> -->
        <!--end::Navigation-->
        <!-- </b-dropdown> -->
        <!--end::Dropdown-->
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.custom-v-dropdown {
  &.dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  &.dropdown-menu {
    margin: 0;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "KTSubheader",
  props: {
    breadcrumbs: Array,
    title: String,
    lineId: String,
    conveyorId: String,
    prevConveyorId: String,
    nextConveyorId: String,
    // conveyorName: String
  },
  data() {
    this.conveyorName = this.$router.history.current.name;
    console.log(this.conveyorName);
    return {
      conveyorName: "null",
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    /**
     * Check if subheader width is fluid
     */
    widthFluid() {
      return this.layoutConfig("subheader.width") === "fluid";
    },

    subheaderClasses() {
      const classes = [];
      const style = this.layoutConfig("subheader.style");
      if (style) {
        classes.push(style);

        if (style === "solid") {
          classes.push("bg-white");
        }

        if (this.layoutConfig("subheader.fixed")) {
          classes.push("border-top");
        }
      }
      return classes.join(" ");
    },
  },
  methods: {
    getConveyors() {
      const conveyorId = this.breadcrumbs[0].conveyorId;
      const conveyors = this.breadcrumbs[0].conveyorIds;

      var index = conveyors.indexOf(parseInt(conveyorId));
      if (index !== -1) {
        this.nextConveyorId = conveyors[index + 1];
        this.prevConveyorId = conveyors[index - 1];
      }
    },
    goToHome(type) {
      this.lineId = this.breadcrumbs[0].lineId;
      this.conveyorId = this.breadcrumbs[0].conveyorId;
      if (type == "next") {
        this.conveyorId = this.nextConveyorId;
        //  this.conveyorId = this.conveyorId + 1
      } else {
        this.conveyorId = this.prevConveyorId;
        // this.conveyorId = this.conveyorId - 1
      }

      this.conveyorId = this.conveyorId;

      this.$store.dispatch(SET_BREADCRUMB, [
        {
          title: `Home > Line ${this.lineId} > Conveyor ${this.conveyorId}`,
          route: `/line/${this.lineId}/`,
          lineId: this.lineId,
          conveyorId: this.conveyorId,
          conveyorIds: this.breadcrumbs[0].conveyorIds,
        },
      ]);

      this.$router.push(
        "/line/" + this.lineId + "/conveyor/" + this.conveyorId
      );
      if (type == "next") {
        this.$root.$emit("GoToNext");
      } else {
        this.$root.$emit("GoToPrev");
      }
    },
  },
  mounted() {
    if (this.breadcrumbs?.[0]?.lineId && this.breadcrumbs?.[0]?.conveyorId) {
      this.getConveyors();
    }
    this.conveyorName = this.$router.history.current.name;
    console.log(this.conveyorName);
  },
  updated() {
    if (this.breadcrumbs?.[0]?.lineId && this.breadcrumbs?.[0]?.conveyorId) {
      if (!this.prevConveyorId && !this.nextConveyorId && !this.conveyorId) {
        this.getConveyors();
      }
    }
  },
};
</script>
