var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"subheader py-2 py-lg-12 subheader-transparent",class:_vm.subheaderClasses,attrs:{"id":"kt_subheader"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap",class:{
      'container-fluid': _vm.widthFluid,
      'container-fluid': !_vm.widthFluid,
    }},[_c('div',{staticClass:"d-flex align-items-center flex-wrap mr-2"},[_c('div',{staticClass:"d-flex flex-column"},[_c('ul',{staticClass:"d-flex align-items-center font-weight-bold my-2"},[_c('router-link',{staticClass:"opacity-75 hover-opacity-100",attrs:{"to":'/'}},[_c('i',{staticClass:"flaticon2-shelter text-white icon-2x"})]),_vm._l((_vm.breadcrumbs),function(breadcrumb,i){return [_c('span',{key:i,staticClass:"label label-dot label-sm bg-white opacity-75 mx-3"}),(breadcrumb.route)?_c('router-link',{key:(i + "-" + (breadcrumb.id)),staticClass:"text-white text-hover-white opacity-75 hover-opacity-100",attrs:{"to":breadcrumb.route}},[_c('h4',[_vm._v(_vm._s(breadcrumb.title))])]):_vm._e(),(!breadcrumb.route)?_c('h4',{key:(i + "-" + (breadcrumb.id)),staticClass:"text-white text-hover-white opacity-75 hover-opacity-100"},[_vm._v(" "+_vm._s(breadcrumb.title)+" ")]):_vm._e()]})],2)])]),_c('div',{staticClass:"d-flex align-items-center"},[(
          _vm.breadcrumbs &&
          _vm.breadcrumbs[0] &&
          _vm.breadcrumbs[0].lineId &&
          _vm.breadcrumbs[0].conveyorId
        )?_c('div',{style:({ textAlign: 'right' })},[_c('b-row',{staticClass:"mt-8 pr-10"},[_c('b-col',{attrs:{"cols":"6"}},[(_vm.prevConveyorId)?_c('a',{staticClass:"btn btn-light font-weight-bold btn-sm",on:{"click":function($event){return _vm.goToHome('prev')}}},[_vm._v(" Prev ")]):_c('a',{staticClass:"btn btn-light font-weight-bold btn-sm disabled",attrs:{"disabled":""}},[_vm._v(" Prev ")])]),_c('b-col',{attrs:{"cols":"6"}},[(_vm.nextConveyorId)?_c('a',{staticClass:"btn btn-light font-weight-bold btn-sm",on:{"click":function($event){return _vm.goToHome('next')}}},[_vm._v(" Next ")]):_c('a',{staticClass:"btn btn-light font-weight-bold btn-sm disabled",attrs:{"disabled":""}},[_vm._v(" Next ")])])],1)],1):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }